<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-container grid-list-xl>
            <v-layout wrap>
                <v-flex xs12>
                    <v-switch v-model="item.enable" :label="$t('Habilitado')" class="my-0 py-0" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field v-model="nameCapitalize" :rules="[rules.required, rules.min(5)]" :label="$t('Como você quer ser chamado')" required />
                </v-flex>
                <v-flex xs12 md12 sm12 v-if="!item.id">
                    <v-text-field :label="$t('E-mail')" v-model="item.email" :rules="[rules.required, rules.email]" />
                    <v-text-field :label="$t('Digite a senha')" v-model="item.password" min="8" :rules="[rules.required, rules.password]"
                        :append-icon="show1 ? 'visibility' : 'visibility_off'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" counter required />
                    <v-text-field :label="$t('Confirme a senha')" v-model="confirmPassword" min="8" :rules="confirmPasswordRules"
                        :append-icon="show2 ? 'visibility' : 'visibility_off'" :type="show2 ? 'text' : 'password'" @click:append="show2 = !show2" counter required />
                </v-flex>
                <v-flex xs12 md12 sm12 v-else>
                    <v-text-field :label="$t('E-mail')" v-model="item.email" disabled />
                </v-flex>
                <v-flex xs12>
                    <v-autocomplete v-model="item.roles" :items="roles" item-value="key" item-text="description" :label="$t('Regras')" @change="changeRole" deletable-chips multiple
                        small-chips />
                </v-flex>
                <v-flex xs12 v-if="screens.length > 0">
                    <v-subheader>{{ $t("Habilitação de telas específicas") }}</v-subheader>
                    <v-divider class="mb-4" />
                    <!-- <v-autocomplete v-model="item.screens" :items="screens" item-value="key" item-text="description" :label="$t('Telas')" deletable-chips multiple small-chips /> -->
                    <div v-for="i in screens" :key="i.key">
                        <v-switch v-model="item.screens" :label="i.description" :value="i.key" class="my-0 py-0" />
                    </div>
                </v-flex>
                <v-flex xs12 md12 sm12>
                    <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate">{{ $t("Salvar") }}</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        nameCapitalize: {
            get: function () {
                return this.item.name;
            },
            set: function (data) {
                this.item.name = this.rules.filters.capitalized(data);
            },
        },
    },

    data() {
        return {
            rules,
            valid: true,
            confirmPassword: null,
            show1: false,
            show2: false,
            itemClean: {
                name: "",
                email: "",
                password: "",
                enable: true,
                roles: ["USER"],
                screens: [],
            },
            item: { ...this.itemClean },
            roles: [
                { key: "USER", description: this.$t("Usuário") },
                { key: "EXPERT", description: this.$t("Especialista") },
                { key: "PRE_EXPERT", description: this.$t("Especialista (Pendente)") },
                { key: "COLLECTOR", description: this.$t("Transportador") },
                { key: "PARTNER", description: this.$t("Parceiro") },
                { key: "OPERATION", description: this.$t("Operador") },
                { key: "ADMIN", description: this.$t("Administrador") },
            ],
            screens: [],
            confirmPasswordRules: [(v) => v === this.item.password || this.$t("Senha diferentes")],
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = { ...this.itemClean };
                    } else {
                        this.item = { ...newVal };
                    }
                    this.changeRole();
                });
            },
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
        changeRole() {
            if (this.item.roles.includes("PARTNER")) {
                if (!this.screens.some(e => e.key === "EXPERT_OPERATION")) {
                    this.screens.push({ key: "EXPERT_OPERATION", description: this.$t("Dashboard - Operações R2PP") });
                }
            } else {
                this.screens = this.screens.filter((el) => el.key.startsWith("EXPERT_") == false);
                this.item.screens = this.item.screens.filter((el) => el.startsWith("EXPERT_") == false);
            }
        },
    },

    mounted() {
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
    },
};
</script>